import classNames from "classnames";
import styles from "./list.module.css";

const List = ({
  children,
  secondary,
  oneHalf,
  oneThird,
  twoThirds,
  checkMarks,
  ...rest
}) => {
  if (!Array.isArray(children)) return null;
  return (
    <ul
      className={classNames(styles.list, {
        [styles.secondary]: secondary,
        [styles.oneHalf]: oneHalf,
        [styles.oneThird]: oneThird,
        [styles.twoThirds]: twoThirds,
        [styles.checkMarks]: checkMarks,
      })}
      {...rest}
    >
      {children.map((child, idx) => (
        <li key={idx}>{child}</li>
      ))}
    </ul>
  );
};

export default List;
