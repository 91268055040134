import classNames from "classnames";
import styles from "./button.module.css";

const ButtonLink = ({ children, important, emphasized, large, ...rest }) => {
  return (
    <a
      className={classNames(styles.button, {
        [styles.important]: important,
        [styles.emphasized]: emphasized,
        [styles.large]: large,
      })}
      {...rest}
    >
      {children}
    </a>
  );
};

export default ButtonLink;
