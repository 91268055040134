import Title from "../../components/Title";
import ButtonLink from "../../components/ButtonLink";
import Paragraph from "../../components/Paragraph";
import Separator from "../../components/Separator";
import Card from "../../components/Card";
import lang from "../../config/lang";
import useLocale from "../../hooks/useLocale";
import classNames from "classnames";
import styles from "./pricingCard.module.css";

const PricingCard = ({ data, free, standard, premium, ...rest }) => {
  const locale = useLocale();
  return (
    <Card
      oneThird
      {...rest}
      className={classNames({
        [styles.free]: free,
        [styles.standard]: standard,
        [styles.premium]: premium,
      })}
    >
      <Title pricing>{data?.title}</Title>
      {data?.price ? (
        <div>
          <span style={{ fontSize: "var(--font-size-header2)" }}>
            {data?.price[0]}
          </span>
          <span style={{ fontSize: "var(--font-size-base)" }}>
            {data?.price[1] || ""}
          </span>
        </div>
      ) : null}
      <div style={{ height: "18px" }} />
      {free ? (
        <ButtonLink important href={"https://demo.kipos.lt"} target={"_blank"}>
          {free ? lang[locale]?.captions?.login : data?.button_caption}
        </ButtonLink>
      ) : (
        <ButtonLink
          important
          href={`mailto:sales@kipos.lt${
            data?.email_subject ? "?subject=" + data?.email_subject : ""
          }`}
        >
          {free ? lang[locale]?.captions?.login : data?.button_caption}
        </ButtonLink>
      )}
      <div style={{ height: "18px" }} />
      {data?.features?.map((item, idx) => (
        <Paragraph list key={idx}>
          {item}
        </Paragraph>
      ))}
      {data?.extra_features ? <Separator /> : null}
      {data?.extra_features?.map((item, idx) => (
        <Paragraph list key={idx}>
          {item}
        </Paragraph>
      ))}
    </Card>
  );
};

export default PricingCard;
