import Title from "./Title";
import Paragraph from "./Paragraph";
import classNames from "classnames";
import styles from "./tile.module.css";

const Tile = ({ children, secondary, ...rest }) => {
  let header;
  let text;
  if (Array.isArray(children)) {
    [header, text] = children;
  } else {
    text = children;
  }

  return (
    <article
      className={classNames(styles.tile, { [styles.secondary]: secondary })}
      {...rest}
    >
      {header ? <Title tile>{header}</Title> : null}
      <Paragraph tile>{text}</Paragraph>
    </article>
  );
};

export default Tile;
