import Title from "./Title";
import Paragraph from "./Paragraph";
import classNames from "classnames";
import styles from "./expandableChip.module.css";

const ExpandableChip = ({ children, onClick, isExpanded, ...rest }) => {
  if (Array.isArray(children)) {
    const [title, text] = children;
    return (
      <li onClick={onClick} className={classNames(styles.expandableChip, {[styles.expanded]: isExpanded})} {...rest}>
        <Title chip>
          {title}
        </Title>
        {isExpanded ? <Paragraph chip>{text}</Paragraph> : null}
      </li>
    );
  }
  return null;
};

export default ExpandableChip;
