import classNames from "classnames";
import styles from "./title.module.css";

const Title = ({
  children,
  section,
  subsection,
  billboard,
  card,
  tile,
  chip,
  titledList,
  emphasized,
  pricing,
  noMargin,
  billboadEmphasis,
  ...rest
}) => {
  if (billboard) {
    return (
      <h1 className={classNames({ [styles.noMargin]: noMargin })} {...rest}>
        {children}
      </h1>
    );
  }
  if (section) {
    return (
      <h2 className={classNames({ [styles.noMargin]: noMargin })} {...rest}>
        {children}
      </h2>
    );
  }
  if (subsection || card) {
    return (
      <h3 className={classNames({ [styles.noMargin]: noMargin })} {...rest}>
        {children}
      </h3>
    );
  }
  if (tile || pricing) {
    return (
      <h4 className={classNames({ [styles.noMargin]: noMargin })} {...rest}>
        {children}
      </h4>
    );
  }
  if (billboadEmphasis) {
    return (
      <h5
        className={classNames(styles.normalWeight, {
          [styles.noMargin]: noMargin,
        })}
        {...rest}
      >
        {children}
      </h5>
    );
  }
  if (titledList) {
    return (
      <h5
        className={classNames(styles.titledList, {
          [styles.noMargin]: noMargin,
        })}
        {...rest}
      >
        {children}
      </h5>
    );
  }
  if (chip) {
    return (
      <h6
        className={classNames(styles.chip, {
          [styles.emphasized]: emphasized,
          [styles.noMargin]: noMargin,
        })}
        {...rest}
      >
        {children}
      </h6>
    );
  }
  return (
    <h6 className={classNames({ [styles.noMargin]: noMargin })} {...rest}>
      {children}
    </h6>
  );
};

export default Title;
