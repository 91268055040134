import styles from "./tile.module.css";

const TileContainer = ({ children, ...rest }) => {
  return (
    <div className={styles.tileContainer} {...rest}>
      {children}
    </div>
  );
};

export default TileContainer;
