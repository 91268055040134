const lang = {
  lt: {
    billboard: {
      header: "Kipos reklama",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed venenatis turpis non nibh auctor porta et sed velit. Morbi nec congue libero. Praesent tempus lorem et metus vulputate tempor. Nullam in imperdiet urna, malesuada facilisis mi. Pellentesque at auctor massa, quis semper mauris. Donec mattis dui et quam vehicula, nec auctor est ornare. Cras a lectus a diam efficitur vestibulum. Aenean consequat lorem lectus.",
      cta_button: "Išbandyti Kipos",
    },
    overview: {
      link_caption: "Apžvalga",
      header: "Apžvalga",
      text: [
        "Dirbate su individualiais projektais, reikalaujančiais daug sudėtingų vizualinių dokumentų (brėžinių, piešinių, nuotraukų, vizualizacijų) derinimo su klientais?",
        "Dirbate vienu metu su trimis-keturiais IT įrankiais, skirtais dokumentų rengimui, saugojimui, dalijimuisi, ir neretai pasimetate dokumentų versijų gausybėje ar klientų el. laiškuose?",
        "Išbandytos projektų valdymo sistemos atrodo pernelyg sudėtingos?",
      ],
      billboard_text:
        "Siūlome sprendimą - tai KIPOS (Komunikacijos Ir Projektų Organizavimo Sistema) - lengvai perprantama užsakymų valdymo, dokumentų dalinimosi ir tiesioginių tekstinių diskusijų vizualinėje medijoje platforma.",
      overview_points: [
        [
          "Paprasta naudoti",
          "Patogi, ergonomiška ir intuityvi vartotojo sąsaja tiek projekto savininkui, tiek jo klientams",
        ],
        [
          "Viskas vienoje vietoje",
          "Užsakymų valdymas, bylų dalijimasis, bendravimas su klientais ir projektų pokyčių stebėjimas",
        ],
        [
          "Išsamus aptarimas",
          "Tiesioginiai komentarai brėžiniuose ir vizualizacijose: supaprastintas bendradarbiavimas, tiesiogiai pridedant komentarus, diskutuojant prie techninių brėžinių, piešinių, vizualizacijų",
        ],
        [
          "Bendradarbiavimas",
          "Dalijimasis projekto informacija ir atnaujinimais",
        ],
        [
          "Būsenos pranešimai",
          "Informavimas el. paštu apie projekto būsenos pasikeitimus, įkeltus/ištrintus dokumentus, parašytą naują komentarą",
        ],
        [
          "Prieinamumas",
          "Veikia internetinėje naršyklėje ir yra prieinama 24 val. per parą visuose įrenginiuose, turinčiuose interneto ryšį: mobiliuosiuose telefonuose, planšetiniuose ar įprastuose kompiuteriuose.",
        ],
      ],
      benefit: "Nauda",
      benefit_points: [
        [
          "Produktyvumas",
          "Padidina produktyvumą ir efektyvumą: automatizuoja užklausų, pasiūlymų suformavimo, projekto sąmatos sudarymo, brėžinių suderinimo su užsakovais procesus, projekto būsenų valdymą, kas gali padėti sutaupyti ir laiko, ir pinigų.",
        ],
        [
          "Komunikacija",
          "Pagerina komunikaciją ir bendradarbiavimą: KIPOS suteikia vieningą platformą visiems projekto dalyviams, kad jie galėtų bendrauti ir bendradarbiauti. Tai gali padėti išvengti klaidų ir užtikrinti, kad projektas būtų vykdomas laiku ir pagal biudžetą.",
        ],
        [
          "Skaidrumas",
          "Pagerina skaidrumą ir sumažina klaidų tikimybę: KIPOS užtikrina, kad visi projekto dalyviai būtų informuoti apie projekto eigą ir naujausius pakeitimus. Tai gali padėti išvengti nesusipratimų ir užtikrinti, kad visi matytų savalaikę informaciją.",
        ],
        [
          "Ryšys su klientu",
          "Padeda pagerinti klientų aptarnavimą: paprasta naudoti KIPOS pateikia Jūsų klientams aktualią informaciją apie jų projektus. Tai leis pagerinti klientų pasitenkinimą ir išlaikys lojalumą.",
        ],
      ],
    },
    functions: {
      link_caption: "Funkcijos",
      header: "Funkcijos",
      text: "Phasellus sed lectus faucibus, porttitor velit tincidunt, tincidunt tellus. Vestibulum mattis quis metus eget aliquam. Nunc vitae felis sed mauris elementum mollis sed vel nisl. Pellentesque quis urna id nisi aliquet vestibulum nec nec nunc. Aenean ornare mi nec varius congue. Vestibulum pretium ornare augue non suscipit. Sed interdum condimentum lacinia.",
      all_in_one_solution: [
        "„Viskas vienoje vietoje” sprendimas",
        "Užsakymų ir projektų valdymas, bendravimas ir dalijimasis dokumentais, pokyčių stebėjimas.",
      ],
      document_sharing: [
        "Dalijimasis dokumentais",
        "Paprasta dalintis bylomis, sekamos bylų versijos.",
      ],
      communication: [
        "Išplėstinė komunikacija",
        "Efektyvi komunikacija tarp klientų, vykdytojo, tiekėjų ir sub-rangovų, skatinant sklandų informacijos mainų procesą. Integracija su el. paštu.",
      ],
      direct_comments: [
        "Tiesioginiai komentarai brėžiniuose ir vizualizacijose",
        "Supaprastintas bendradarbiavimas, tiesiogiai pridedant komentarus, diskutuojant prie techninių brėžinių, piešinių, vizualizacijų.",
      ],
      /*
      centralized_information: [
        "Centrinis informacijos mazgas",
        "Atskaitos („tiesos”) taškas visiems projekto dalyviams, užtikrinant aiškumą, skaidrumą ir suderinamumą.",
      ],
      */
    },
    pricing: {
      link_caption: "Kaina/Planai",
      header: "Kaina/Planai",
      free: {
        title: "Nemokamas",
        price: ["€0"],
        features: ["1 vartotojas", "∞ klientų", "10 aktyvių projektų"],
        extra_features: [
          "5 GB saugykloje",
          "< 24 val.* atsakymas į užklausas el. paštu",
        ],
      },
      standard: {
        title: "Standartinis",
        email_subject: "Standartinio plano užsakymas",
        button_caption: "Rinktis planą",
        price: ["€19", "/vartotojas/mėn."],
        features: ["∞ vartotojų", "∞ klientų", "∞ projektų"],
        extra_features: [
          "30 GB saugykloje",
          "< 4 val.* atsakymas į užklausas el. paštu",
        ],
      },
      premium: {
        title: "Premium",
        email_subject: "Premium plano užsakymas",
        button_caption: "Rinktis planą",
        price: ["€49", "/vartotojas/mėn."],
        features: ["∞ vartotojų", "∞ klientų", "∞ projektų"],
        extra_features: [
          "∞ GB saugykloje",
          "< 1 val.* atsakymas į užklausas el. paštu",
          "Pagalba telefonu",
          "Savas logotipas",
          "Stiliaus pritaikymas",
          "Savas domenas",
        ],
      },
      footnotes: ["* - Darbo dienomis nuo 8.00 iki 18.00 val."],
    },
    faq: {
      link_caption: "DUK",
      header: "DUK",
      text: "Proin hendrerit sem vitae lacinia sodales. Proin fermentum vulputate urna. Praesent et lobortis mi. Quisque luctus et massa nec cursus. Aenean ut pharetra urna. In iaculis suscipit erat, semper convallis ipsum luctus sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel bibendum erat. Aenean aliquet justo velit. Sed vitae mattis elit. Phasellus semper tortor sit amet velit ultrices, eget rhoncus enim fringilla. Aliquam vel est velit. Donec nec tempus lectus. Integer non est id metus rhoncus tristique eget a est. Morbi bibendum eu risus at tempus. Quisque pellentesque id velit et imperdiet.",
      questions: [
        [
          "Kokių priemonių reikia norint naudotis KIPOS?",
          "Sistema sukurta internetinės naršyklės pagrindu, tad reikės kompiuterio ar kito mobilaus įrenginio ir interneto ryšio.",
        ],
        [
          "Ar mano klientai taip pat galės jungtis prie sistemos?",
          "Taip, KIPOS yra vieninga platforma visiems projekto dalyviams, kad jie galėtų bendrauti ir bendradarbiauti, sekti projekto eigą bei visada matyti savalaikę informaciją. Klientų prisijungimus valdote Jūs, pakviesdami juos į konkrečius projektus.",
        ],
        [
          "Ar KIPOS sistemoje tuos pačius duomenis gali matyti ir valdyti keli asmenys?",
          "Taip, informacijos valdymas priklauso nuo to, kuri teisių grupė priskiriama sistemos vartotojui.",
        ],
        [
          "Ar tinka mano įmonei?",
          "KIPOS yra debesyje veikiantis užsakymų valdymo sprendimas, skirtas mažoms įmonėms ir asmenims, užsiimantiems projektine veikla, ir norintiems valdyti užsakymus, sekti projektų eigą, turėti tvarkingai suorganizuotą visą projekto dokumentaciją bei susirašinėjimą su klientais vienoje visada lengvai pasiekiamoje vietoje. ",
        ],
        [
          "Kaip veikia Jūsų kainodara?",
          "Tai daugiapakopė prenumeratos paslauga, todėl galutinė prenumeratos kaina priklauso nuo žmonių Jūsų komandoje skaičiaus ir nuo to, koks planas Jums labiausiai tinka.",
        ],
        [
          "Kaip atsiskaitoma už naudojimąsi sistema?",
          "Sistema prenumeruojama, sąskaitos siunčiamos kiekvieno mėnesio pirmą dieną už einamąjį mėnesį. Bet kada iki naujo mėnesio pradžios galite atsisakyti prenumeratos ar pakeisti planą į aukštesnį/žemesnį.",
        ],
        [
          "Kuris planas man tinka?",
          "Peržiūrėkite aukščiau pateiktą planų palyginimo lentelę ir apžvelkite siūlomus planus ir funkcijas. Rekomenduojame pradėti nuo nemokamo plano, kurį bet kada galėsite pakeisti į mokamą. Jei Jums reikia pagalbos renkantis planą, %CONTACT_US%, mielai Jums padėsime!",
        ],
        [
          "Kokią pagalbą teikiate?",
          "Mes turime savo klientų aptarnavimo komandą, kuri mielai jums padės iškilus bet kokiems klausimams – nedvejodami %CONTACT_SUPPORT% jiems el. laišką.",
        ],
        [
          "Aš turiu kitų klausimų. Kas gali man padėti?",
          "Jei turite kitų klausimų dėl KIPOS ar registracijos proceso, %CONTACT_US% su mumis. Mes laukiame Jūsų laiškų!",
        ],
      ],
    },
    help: {
      link_caption: "Pagalba",
      header: "Pagalba",
    },
    contacts: {
      link_caption: "Kontaktai",
      header: "Kontaktai",
      support_contacts: [
        ["Bendrieji klausimai", "info@kipos.lt"],
        ["Pagalba vartotojams", "support@kipos.lt"],
        ["Sistemos įsigijimo klausimai", "sales@kipos.lt"],
        ["Partnerystės klausimai", "partners@kipos.lt"],
      ],
    },
    footer: {
      address: ["UAB „Gasefis”", "Lukiškių g. 5", "01108 Vilnius", "Lietuva"],
      phone_email: ["Tel: +370 5 279 7102", "El. paštas: info@kipos.lt"],
      code_account: [
        "Įmonės kodas: 303575354",
        "PVM mokėtojo kodas: LT100011754219",
        "AB SEB bankas",
        "A/s LT967044060008010182",
      ],
    },
    captions: {
      learn_more: "Sužinokite daugiau",
      login: "Prisijungti",
      try_for_free: "Išbandyti nemokamai",
    },
    links: {
      CONTACT_US: ["susisiekite", "mailto:info@kipos.lt"],
      CONTACT_SUPPORT: ["siųskite", "mailto:support@kipos.lt"],
      HELP_CENTER: ["Pagalbos Centre", "#help_center"],
    },
  },
};

export default lang;
