import classNames from "classnames";
import styles from "./footer.module.css";

const Footer = ({ children, ...rest }) => {
  return (
    <footer className={classNames(styles.footer)} {...rest}>
      <div className={styles.footerContainer}>{children}</div>
    </footer>
  );
};

export default Footer;
