import styles from "./navLink.module.css";

const NavLinks = ({ children }) => {
  let content = [];
  if (Array.isArray(children)) {
    content = children;
  }
  return (
    <ul className={styles.navLinkList}>
      {content.map((item, idx) => (
        <li key={idx} className={styles.navLinkItem}>{item}</li>
      ))}
    </ul>
  );
};

export default NavLinks;
