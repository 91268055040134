import Image from "./Image";
import classNames from "classnames";
import styles from "./logo.module.css";

const Logo = ({ header, footer }) => {
  return (
    <Image
      src={"logo.png"}
      className={classNames(styles.logo, {
        [styles.headerLogo]: header,
        [styles.footerLogo]: footer,
      })}
    />
  );
};

export default Logo;
