import classNames from "classnames";
import styles from "./card.module.css";

const Card = ({
  children,
  oneHalf,
  oneThird,
  twoThirds,
  noVerticalPadding,
  className,
  ...rest
}) => {
  return (
    <article
      className={classNames(className, styles.card, {
        [styles.oneHalf]: oneHalf,
        [styles.oneThird]: oneThird,
        [styles.twoThirds]: twoThirds,
        [styles.noVerticalPadding]: noVerticalPadding,
      })}
      {...rest}
    >
      {children}
    </article>
  );
};

export default Card;
