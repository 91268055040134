import classNames from "classnames";
import styles from "./image.module.css";

const Image = ({ children, featureCard, fill, ...rest }) => {
  return (
    <img
      className={classNames(styles.image, {
        [styles.featureCard]: featureCard,
        [styles.fill]: fill,

      })}
      alt={rest?.alt || "image"}
      {...rest}
    >
      {children}
    </img>
  );
};

export default Image;
