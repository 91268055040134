import Section from "../../components/Section";
import Title from "../../components/Title";
import Paragraph from "../../components/Paragraph";
import TileContainer from "../../components/TileContainer";
import PricingCard from "./PricingCard";
import useLocale from "../../hooks/useLocale";
import lang from "../../config/lang";
import routes from "../../config/routes";

const Pricing = () => {
  const locale = useLocale();
  return (
    <Section secondary id={routes?.pricing}>
      <Title section>{lang[locale]?.pricing?.header}</Title>
      <TileContainer>
        <PricingCard free data={lang[locale]?.pricing?.free} />
        <PricingCard standard data={lang[locale]?.pricing?.standard} />
        <PricingCard premium data={lang[locale]?.pricing?.premium} />
      </TileContainer>
      <Paragraph secondary>{lang[locale]?.pricing?.footnotes}</Paragraph>
    </Section>
  );
};

export default Pricing;
