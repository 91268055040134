import classNames from "classnames";
import styles from "./textLink.module.css";

const TextLink = ({
  children,
  important,
  mobileNav,
  inline,
  footer,
  ...rest
}) => {
  return (
    <a
      className={classNames(styles.textLink, {
        [styles.important]: important,
        [styles.mobileNav]: mobileNav,
        [styles.footer]: footer,
        [styles.inline]: inline,
      })}
      {...rest}
    >
      {children}
    </a>
  );
};

export default TextLink;
