import Section from "../../components/Section";
import Title from "../../components/Title";
import Paragraph from "../../components/Paragraph";
import useLocale from "../../hooks/useLocale";
import FeatureCard from "../../components/FeatureCard";
import lang from "../../config/lang";
import routes from "../../config/routes";
import Image from "../../components/Image";

const Functions = () => {
  const locale = useLocale();
  return (
    <Section secondary id={routes?.functions}>
      <Title section>{lang[locale]?.functions?.header}</Title>
      <FeatureCard>
        <>
          <Title card>{lang[locale]?.functions?.all_in_one_solution[0]}</Title>
          <Paragraph card>
            {lang[locale]?.functions?.all_in_one_solution[1]}
          </Paragraph>
        </>
        <Image src={`screenshots/${locale}/all_in_one_place.png`} featureCard />
      </FeatureCard>
      <FeatureCard splitOneTwo contentOnRight>
        <>
          <Title card>{lang[locale]?.functions?.document_sharing[0]}</Title>
          <Paragraph card>
            {lang[locale]?.functions?.document_sharing[1]}
          </Paragraph>
        </>
        <Image src={`screenshots/${locale}/sharing2.png`} featureCard />
      </FeatureCard>
      <FeatureCard>
        <>
          <Title card>{lang[locale]?.functions?.communication[0]}</Title>
          <Paragraph card>
            {lang[locale]?.functions?.communication[1]}
          </Paragraph>
        </>
        <Image src={`screenshots/${locale}/notification2.png`} featureCard />
      </FeatureCard>
      <FeatureCard splitOneTwo contentOnRight>
        <>
          <Title card>{lang[locale]?.functions?.direct_comments[0]}</Title>
          <Paragraph card>
            {lang[locale]?.functions?.direct_comments[1]}
          </Paragraph>
        </>
        <Image src={`screenshots/${locale}/add_comments.png`} featureCard />
      </FeatureCard>
      {/* 
      <FeatureCard>
        <>
          <Title card>
            {lang[locale]?.functions?.centralized_information[0]}
          </Title>
          <Paragraph card>
            {lang[locale]?.functions?.centralized_information[1]}
          </Paragraph>
        </>
        <Image
          src={`screenshots/${locale}/screenshot2-202311.png`}
          featureCard
        />
      </FeatureCard>
      */}
    </Section>
  );
};

export default Functions;

/*
      centralized_information: [
        "Centrinis informacijos mazgas",
        "Atskaitos („tiesos”) taškas visiems projekto dalyviams, užtikrinant aiškumą, skaidrumą ir suderinamumą.",
      ],
*/
