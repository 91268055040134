import classNames from "classnames";
import styles from "./section.module.css";

const Section = ({
  children,
  centered,
  fullWidth,
  secondary,
  noPadding,
  ...rest
}) => {
  return (
    <section
      className={classNames(styles.section, {
        [styles.secondary]: secondary,
        [styles.noPadding]: noPadding,
      })}
      {...rest}
    >
      <div
        className={classNames(styles.sectionContainer, {
          [styles.fullWidth]: fullWidth,
          [styles.centered]: centered,
          [styles.noPadding]: noPadding,
        })}
      >
        {children}
      </div>
    </section>
  );
};

export default Section;
