import Section from "../../components/Section";
import Title from "../../components/Title";
import Image from "../../components/Image";
import Tile from "../../components/Tile";
import TileContainer from "../../components/TileContainer";
import List from "../../components/List";
import OverviewCard from "./OverviewCard";
import ButtonLink from "../../components/ButtonLink";
import useLocale from "../../hooks/useLocale";
import lang from "../../config/lang";
import routes from "../../config/routes";

const images = [
  "easyToUse.png",
  "allInOne.png",
  "messaging.png",
  "sharing.png",
  "notification.png",
  "cloud.png",
];

const Overview = () => {
  const locale = useLocale();
  return (
    <>
      <Section secondary noPadding id={routes?.overview}>
        <Image
          src={`screenshots/${locale}/landing.png`}
          fill
          style={{
            width: "100%",
            maxHeight: "50vh",
            minHeight: "var(--min-height-section)",
          }}
        />
      </Section>
      <Section secondary>
        {/* <Paragraph secondary>{lang[locale]?.overview?.text}</Paragraph> */}
        <List secondary twoThirds checkMarks>
          {lang[locale]?.overview?.text}
        </List>
        <Title noMargin billboadEmphasis>
          {lang[locale]?.overview?.billboard_text}
        </Title>
        {/* <TitledList>{lang[locale]?.overview?.overview_points}</TitledList> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "32px 0",
          }}
        >
          <ButtonLink
            large
            important
            href={"https://demo.kipos.lt"}
            target={"_blank"}
          >
            {lang[locale]?.captions?.try_for_free}
          </ButtonLink>
        </div>
      </Section>
      <Section>
        <TileContainer>
          {lang[locale]?.overview?.overview_points?.map((item, idx) => (
            <OverviewCard key={idx} image={images[idx]}>
              {item}
            </OverviewCard>
          ))}
        </TileContainer>
        <TileContainer>
          {lang[locale]?.overview?.benefit_points?.map((item, idx) => (
            <Tile key={idx}>{item}</Tile>
          ))}
        </TileContainer>
      </Section>
    </>
  );
};

export default Overview;
