import { useState } from "react";
import ExpandableChip from "./ExpandableChip";
import styles from "./list.module.css";

const ExpandableList = ({ children, ...rest }) => {
  const [expandedItem, setExpandedItem] = useState();
  if (!Array.isArray(children)) return null;

  const handleToggle = (idx) => {
    setExpandedItem((state) => (state !== idx ? idx : undefined));
  };

  return (
    <ul className={styles.expandableList} {...rest}>
      {children.map((child, idx) => {
        if (Array.isArray(child)) {
          return (
            <ExpandableChip
              key={idx}
              isExpanded={expandedItem === idx}
              onClick={() => {
                handleToggle(idx);
              }}
            >
              {child}
            </ExpandableChip>
          );
        }
        return null;
      })}
    </ul>
  );
};

export default ExpandableList;
