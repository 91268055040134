import Heading from "./features/heading/Heading";
import Footing from "./features/footing/Footing";
import Overview from "./features/overview/Overview";
import Functions from "./features/functions/Functions";
import Pricing from "./features/pricing/Pricing";
import Faq from "./features/faq/Faq";
import Contacts from "./features/contacts/Contacts";

const App = () => {
  return (
    <>
      <Heading />
      {/* <Billboard /> */}
      <Overview />
      <Functions />
      <Pricing />
      <Faq />
      <Contacts />
      <Footing />
    </>
  );
};

export default App;
