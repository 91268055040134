import Header from "../../components/Header";
import Logo from "../../components/Logo";
import NavLinks from "./NavLinks";
import ButtonLink from "../../components/ButtonLink";
import MobileNavLinks from "./MobileNavLinks";
import useLocale from "../../hooks/useLocale";
import lang from "../../config/lang";
import routes from "../../config/routes";

const Heading = () => {
  const locale = useLocale();
  return (
    <Header id={"#top"}>
      <a href={"#top"}>
        <Logo header />
      </a>
      <NavLinks>
        <a href={`#${routes.overview}`}>
          {lang[locale]?.overview?.link_caption}
        </a>
        <a href={`#${routes.functions}`}>
          {lang[locale]?.functions?.link_caption}
        </a>
        <a href={`#${routes.pricing}`}>{lang[locale]?.pricing?.link_caption}</a>
        <a href={`#${routes.faq}`}>{lang[locale]?.faq?.link_caption}</a>
        <a href={`#${routes.contacts}`}>
          {lang[locale]?.contacts?.link_caption}
        </a>
      </NavLinks>
      <ButtonLink emphasized href={"https://demo.kipos.lt"} target={"_blank"}>
        {lang[locale]?.captions?.login}
      </ButtonLink>
      <MobileNavLinks></MobileNavLinks>
    </Header>
  );
};

export default Heading;
