import { useState, useEffect, useRef } from "react";
import useLocale from "../../hooks/useLocale";
import { useLocation } from "react-router-dom";
import Icon from "../../components/Icon";
import TextLink from "../../components/TextLink";
import routes from "../../config/routes";
import lang from "../../config/lang";
import classNames from "classnames";
import styles from "./mobileNavLinks.module.css";

const MobileNavLinks = () => {
  const location = useLocation();
  const [showMobileNav, setShowMobileNav] = useState(false);
  const showMobileRef = useRef();
  showMobileRef.current = showMobileNav;
  const burgerRef = useRef();
  const mobileDropdownRef = useRef();
  const locale = useLocale();

  const handleBurgerClick = () => {
    setShowMobileNav((state) => !state);
  };

  const handleMouseDown = (event) => {
    if (burgerRef.current.contains(event.target)) {
      handleBurgerClick();
    } else if (
      !mobileDropdownRef.current.contains(event.target) &&
      showMobileRef.current
    ) {
      setShowMobileNav((state) => (state === false ? state : false));
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setShowMobileNav((state) => (state === false ? state : false));
    // eslint-disable-next-line
  }, [location]);

  return (
    <div className={styles.mobileNavLinksContainer}>
      <div ref={burgerRef}>
        <Icon burger={!showMobileNav} cross={showMobileNav} />
      </div>
      <div
        className={classNames(styles.screener, {
          [styles.hidden]: !showMobileNav,
        })}
      />
      <div
        ref={mobileDropdownRef}
        className={classNames(styles.navDropDown, {
          [styles.hidden]: !showMobileNav,
        })}
      >
        <TextLink mobileNav href={`#${routes.overview}`}>
          {lang[locale]?.overview?.link_caption}
        </TextLink>
        <TextLink mobileNav href={`#${routes.functions}`}>
          {lang[locale]?.functions?.link_caption}
        </TextLink>
        <TextLink mobileNav href={`#${routes.pricing}`}>
          {lang[locale]?.pricing?.link_caption}
        </TextLink>
        <TextLink mobileNav href={`#${routes.faq}`}>
          {lang[locale]?.faq?.link_caption}
        </TextLink>
        <TextLink mobileNav href={`#${routes.contacts}`}>
          {lang[locale]?.contacts?.link_caption}
        </TextLink>
      </div>
    </div>
  );
};

export default MobileNavLinks;
