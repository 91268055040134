import TextLink from "./TextLink";
import useLocale from "../hooks/useLocale";
import lang from "../config/lang";
import classNames from "classnames";
import styles from "./paragraph.module.css";

const Paragraph = ({
  children,
  billboard,
  card,
  secondary,
  titledList,
  list,
  tile,
  chip,
  footer,
  ...rest
}) => {
  const locale = useLocale();
  let linkNames = [];
  if (lang[locale]?.links) {
    linkNames = Object.keys(lang[locale]?.links);
  }

  const insertEmail = (text) => {
    if (!(typeof text === "string" && text.includes("@"))) {
      return text;
    }
    let emails = [];
    const emailRegex =
      /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|([a-zA-Z\-0-9]+\.[a-zA-Z]{2,}))/g;
    let emailFound;
    let textArray = [text];
    while ((emailFound = emailRegex.exec(text))) {
      emails.push(emailFound[0]);
      let splitByEmail = textArray[textArray.length - 1].split(emailFound[0]);
      splitByEmail.splice(1, 0, emailFound[0]);
      textArray = [...textArray.slice(0, -1), ...splitByEmail];
      textArray = textArray.filter((item) => item !== "");
    }
    return textArray.map((item) => {
      if (item.includes("@")) {
        return (
          <TextLink footer={footer} key={item} inline href={`mailto:${item}`}>
            {item}
          </TextLink>
        );
      }
      return item;
    });
  };

  const insertLink = (text) => {
    if (typeof text !== "string") return text;
    const textArray = text.split("%");
    if (textArray.length > 2) {
      const result = textArray.map((item, idx) => {
        if (linkNames.indexOf(item) > -1) {
          return (
            <TextLink key={idx} inline href={lang[locale]?.links[item][1]}>
              {lang[locale]?.links[item][0]}
            </TextLink>
          );
        }
        // return item;
        return insertEmail(item);
      });
      return result;
    }
    return insertEmail(text);
  };

  if (Array.isArray(children)) {
    return (
      <>
        {children.map((child, idx) => (
          <p
            key={idx}
            className={classNames(styles.paragraph, {
              [styles.secondary]: secondary,
              [styles.billboardParagraph]: billboard,
              [styles.cardParagraph]: card,
              [styles.titledList]: titledList,
              [styles.list]: list,
              [styles.chip]: chip,
              [styles.footer]: footer,
            })}
            {...rest}
          >
            {insertLink(child)}
          </p>
        ))}
      </>
    );
  }
  return (
    <p
      className={classNames({
        [styles.secondary]: secondary,
        [styles.billboardParagraph]: billboard,
        [styles.cardParagraph]: card,
        [styles.titledList]: titledList,
        [styles.list]: list,
        [styles.chip]: chip,
        [styles.footer]: footer,
      })}
      {...rest}
    >
      {insertLink(children)}
    </p>
  );
};

export default Paragraph;
