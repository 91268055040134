import Section from "../../components/Section";
import Title from "../../components/Title";
import Paragraph from "../../components/Paragraph";
import TileContainer from "../../components/TileContainer";
import Card from "../../components/Card";
import useLocale from "../../hooks/useLocale";
import lang from "../../config/lang";
import routes from "../../config/routes";

const Contacts = () => {
  const locale = useLocale();
  const contactCards = lang[locale]?.contacts?.support_contacts?.map(
    (item, idx) => {
      return (
        <Card oneHalf noVerticalPadding key={idx}>
          <Title noMargin>{item[0]}</Title>
          <Paragraph titledList>{item[1]}</Paragraph>
        </Card>
      );
    }
  );

  return (
    <Section secondary id={routes?.contacts}>
      <Title section>{lang[locale]?.contacts?.header}</Title>
      <TileContainer>{contactCards}</TileContainer>
    </Section>
  );
};

export default Contacts;
