import classNames from "classnames";
import styles from "./header.module.css";

const Header = ({ children, ...rest }) => {
  return (
    <header className={classNames(styles.header)} {...rest}>
      <div className={styles.headerContainer}>{children}</div>
    </header>
  );
};

export default Header;
