import Section from "../../components/Section";
import Title from "../../components/Title";
import ExpandableList from "../../components/ExpandableList";
import useLocale from "../../hooks/useLocale";
import lang from "../../config/lang";
import routes from "../../config/routes";

const Faq = () => {
  const locale = useLocale();
  return (
    <Section id={routes?.faq}>
      <Title section>{lang[locale]?.faq?.header}</Title>
      <ExpandableList>{lang[locale]?.faq?.questions}</ExpandableList>
    </Section>
  );
};

export default Faq;
