import Title from "../../components/Title";
import Image from "../../components/Image";
import Paragraph from "../../components/Paragraph";
import Card from "../../components/Card";

const OverviewCard = ({ children, image, ...rest }) => {
  return (
    <Card oneThird {...rest}>
      {Array.isArray(children) ? (
        <>
          {image ? (
            <div style={{ marginBottom: "var(--spacing-tile)" }}>
              <Image src={image} width={80} />
            </div>
          ) : null}
          <Title titledList>{children[0]}</Title>
          <Paragraph titledList>{children[1]}</Paragraph>
        </>
      ) : null}
    </Card>
  );
};

export default OverviewCard;
