import classNames from "classnames";
import styles from "./featureCard.module.css";

const PlainCard = ({ children, ...rest }) => {
  return (
    <article className={styles.featureCard} {...rest}>
      {children}
    </article>
  );
};

const TwoPartCard = ({ children, contentOnTop, contentOnRight, ...rest }) => {
  return (
    <article
      className={classNames(styles.featureCard, {
        [styles.contentOnTop]: contentOnTop,
        [styles.contentOnRight]: contentOnRight,
      })}
      {...rest}
    >
      {children}
    </article>
  );
};

const DescriptionPart = ({ children, splitOneTwo }) => {
  return (
    <div
      className={classNames(styles.description, {
        [styles.splitOneTwo]: splitOneTwo,
      })}
    >
      {children}
    </div>
  );
};

const ContentPart = ({ children, splitOneTwo }) => {
  return (
    <div
      className={classNames(styles.content, {
        [styles.splitOneTwo]: splitOneTwo,
      })}
    >
      {children}
    </div>
  );
};

const FeatureCard = ({
  children,
  contentOnTop,
  contentOnRight,
  splitOneTwo,
  ...rest
}) => {
  if (!Array.isArray(children)) {
    <PlainCard {...rest}>{children}</PlainCard>;
  }
  return (
    <TwoPartCard
      contentOnTop={contentOnTop}
      contentOnRight={contentOnRight}
      {...rest}
    >
      <DescriptionPart splitOneTwo={splitOneTwo}>{children[0]}</DescriptionPart>
      <ContentPart splitOneTwo={splitOneTwo}>{children[1]}</ContentPart>
    </TwoPartCard>
  );
};

export default FeatureCard;
